/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-11-19",
    versionChannel: "nightly",
    buildDate: "2024-11-19T00:06:38.272Z",
    commitHash: "c901b967473ab9810e6b654b997ef757bcf456c5",
};
